import hello from 'hellojs';
import * as Sentry from '@sentry/browser';
import {getEnvironment, getVersion} from './config';

if (process.env.SENTRY_DSN && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: getEnvironment(),
    release: 'sparkit-web@' + getVersion(),
  });
}

window.hello = hello;
